<template>
  <svg :role="role">
    <use
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :xlink:href="svgPath">
    </use>
  </svg>
</template>

<script setup lang="ts">
    import { computed } from 'vue';

    interface Props {
        icon : string
        role? : 'img' | 'application' | 'document'
        spritePath? : string
    }

    const props = withDefaults(defineProps<Props>(), {
        role : 'img',
        spritePath : '/static/icons/sprite.svg'
    });

    const svgPath = computed(() => {
        return `${props.spritePath}#${props.icon}`;
    });
</script>
